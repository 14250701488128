import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



const routes = [
  { 
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: '会员登录'
    },  
  },
  { 
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue'),
    meta: {
      title: '个人中心'
    },  
  },
  { 
    path: '/coupon',
    name: 'Coupon',
    component: () => import('../views/Coupon.vue'),
    meta: {
      title: '我的优惠券'
    },  
  },
  { 
    path: '/bayrecord',
    name: 'BayRecord',
    component: () => import('../views/BayRecord.vue'),
    meta: {
      title: '购买记录'
    },  
  },
  { 
    path: '/myincome',
    name: 'MyIncome',
    component: () => import('../views/MyIncome.vue'),
    meta: {
      title: '我的收益'
    },  
  },
  { 
    path: '/example',
    name: 'Example',
    component: () => import('../views/Example.vue'),
    meta: {
      title: '示例'
    },  
  },
  { 
    path: '/baymember',
    name: 'BayMember',
    component: () => import('../views/BayMember.vue'),
    meta: {
      title: '购买会员'
    },  
  },
  { 
    path: '/exclusiveinvite',
    name: 'ExclusiveInvite',
    component: () => import('../views/ExclusiveInvite.vue'),
    meta: {
      title: '专属邀请'
    },  
  },
  { 
    path: '/invitefriend',
    name: 'InviteFriend',
    component: () => import('../views/InviteFriend.vue'),
    meta: {
      title: '邀请好友'
    },  
  },
  { 
    path: '/inviterecord',
    name: 'InviteRecord',
    component: () => import('../views/InviteRecord.vue'),
    meta: {
      title: '邀请记录'
    },  
  },
  { 
    path: '/procooperate',
    name: 'ProCooperate',
    component: () => import('../views/ProCooperate.vue'),
    meta: {
      title: '推广合作'
    },  
  },
  
  { 
    path: '/receivecoupon',
    name: 'ReceiveCoupon',
    component: () => import('../views/ReceiveCoupon.vue'),
    meta: {
      title: '领取优惠券'
    },  
  },
  { 
    path: '/',
    name: 'Talk',
    component: () => import('../views/Talk.vue'),
    meta: {
      title: 'AI对话'
    },  
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
