import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible/index.js'
import { Toast } from 'vant';
import html2canvas from 'html2canvas'

Vue.config.productionTip = false

Vue.use(vant)
Vue.use(Toast);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title

  }
  next()
})
window.eventBus = new Vue()

Vue.prototype.$axios = axios
Vue.prototype.$html2canvas = html2canvas

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
