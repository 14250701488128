<template>
  <div class="tabbar-view" v-show="isShow">
    <van-tabbar v-model="active">
      <van-tabbar-item
        :to="item.path"
        v-for="(item, index) in tabList"
        :key="index"
        :class="[index == 0 ? 'tabbar-item-ai' : '']"
      >
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.afterIcon : item.beforIcon" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  name: "TabBar",
  data() {
    return {
      tabList: [
        {
          icon: "chat-o",
          text: "AI对话",
          path: "/",
          beforIcon: require("../assets/images/tabBar/Tab-chat.svg"),
          afterIcon: require("../assets/images/tabBar/Tab-chat-s.svg"),
        },
        {
          icon: "like-o",
          text: "专属邀请",
          path: "/exclusiveinvite",
          beforIcon: require("../assets/images/tabBar/Tab-invite.svg"),
          afterIcon: require("../assets/images/tabBar/Tab-invite-s.svg"),
        },
        {
          icon: "shopping-cart-o",
          text: "购买会员",
          path: "/baymember",
          beforIcon: require("../assets/images/tabBar/Tab-cart.svg"),
          afterIcon: require("../assets/images/tabBar/Tab-cart-s.svg"),
        },
        {
          icon: "user-o",
          text: "个人中心",
          path: "/my",
          beforIcon: require("../assets/images/tabBar/Tab-user.svg"),
          afterIcon: require("../assets/images/tabBar/Tab-user-s.svg"),
        },
      ],
      active: 0,

      isShow: true,
    };
  },
  mounted() {
    if (this.$route.path == "/") {
      this.active = 0;
    } else if (this.$route.path == "/exclusiveinvite") {
      this.active = 1;
    } else if (this.$route.path == "/baymember") {
      this.active = 2;
    } else if (this.$route.path == "/my") {
      this.active = 3;
    }

    if (
      this.$route.path == "/exclusiveinvite" ||
      this.$route.path == "/baymember" ||
      this.$route.path == "/my"
    ) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
    this.wxUtils();
  },
  watch: {
    $route: function (newUrl, oldUrl) {
      if (newUrl.path == "/") {
        this.active = 0;
      } else if (newUrl.path == "/exclusiveinvite") {
        this.active = 1;
      } else if (newUrl.path == "/baymember") {
        this.active = 2;
      } else if (newUrl.path == "/my") {
        this.active = 3;
      }

      if (
        newUrl.path == "/exclusiveinvite" ||
        newUrl.path == "/baymember" ||
        newUrl.path == "/my"
      ) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }

      this.wxUtils();
    },
  },
  methods: {
    wxUtils: function () {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady();
      }
    },
    onBridgeReady: function () {
      WeixinJSBridge.call("hideOptionMenu");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/css/tabbar.scss";
</style>